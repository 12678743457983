import type { ComponentType } from 'react';
import { lazy } from 'react';

interface RetryOptions {
    maxRetries?: number;
    initialDelayMs?: number;
    maxDelayMs?: number;
    shouldReload?: boolean;
}

const DEFAULT_OPTIONS: Required<RetryOptions> = {
    maxRetries: 3,
    initialDelayMs: 1000,
    maxDelayMs: 10_000,
    shouldReload: true,
};

function createRetryableLazyLoader<T extends ComponentType<any>>(
    importFunction: () => Promise<{ default: T }>,
    options: RetryOptions = {},
) {
    const { maxRetries, initialDelayMs, maxDelayMs, shouldReload } = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const retryImport = async (attempt = 1): Promise<{ default: T }> => {
        try {
            return await importFunction();
        } catch (error) {
            if (attempt >= maxRetries) {
                console.error(
                    `Failed to load chunk after ${maxRetries} attempts`,
                    error,
                );
                if (shouldReload) {
                    window.location.reload();
                }
                throw error;
            }

            // Calculate delay with exponential backoff
            const delay = Math.min(
                initialDelayMs * 2 ** (attempt - 1),
                maxDelayMs,
            );

            console.warn(
                `Chunk load failed, retrying (${attempt}/${maxRetries}) in ${delay}ms...`,
                error,
            );

            await new Promise((resolve) => {
                setTimeout(resolve, delay);
            });
            return retryImport(attempt + 1);
        }
    };

    return lazy(() => retryImport());
}

// Usage example with more specific typing
export function retryLazyLoad<T extends ComponentType<any>>(
    importFunction: () => Promise<{ default: T }>,
    options?: RetryOptions,
) {
    return createRetryableLazyLoader(importFunction, options);
}
